<template>
  <div class="record">
    <div class="backlink ama18">
      <router-link
              :to="`/piekirpedia/${$route.params.line}/${$route.params.point}`" 
            >
            <img src="@/assets/images/leftarrow.svg" />Piekirpedia
            </router-link>
    </div>
    <div class="topimage">
      <img
        :src="
          `${$config.images.server}/images/${record.recordmedia}?width=${windowWidth}&height=590&fit=cover&background=ffffff&amp;position=left%20top`
        "
      />
    </div>
    <div class="data">
      <div class="keywords ama18">
        <span class="keyword">
          {{ record.date }}
        </span>
        <span class="keyword" v-for="keyword in keywords" :key="keyword">
          {{ keyword }}
        </span>
      </div>
      <div class="title ama60">
        {{ record.title }}
      </div>
      <div class="subtitle ama40">
        {{ record.subtitle }}
      </div>
      <!-- <div class="description pop24"  v-append.sync="html" @appended="appended">coucou</div> -->
      <div class="description pop24" v-html="record.description">
      </div>
      <!-- <div class="description pop24" v-html="tooltip(record.description)"> 
      </div> -->
      <!--<div class="description pop24" >
        <p>description and <a v-tooltip.top="'Enter your username'">other information</a></p>
      </div> -->
    </div>
  </div>
</template>

<script>
import lifeService from "../services/life_service"

export default {
  data() {
    return {
      record: {},
      windowWidth: (this.windowWidth = window.innerWidth),
      keywords: {},
      prevRoute: null,
      html: `<p>hello <i>world</i></p>`
    }
  },
  recordservice: null,
  created() {
    this.lifeService = new lifeService()
  },
  mounted() {
    //this.recordservice.getById(this.$router.currentRoute.value.params.id).then(data => this.record = data);
    var t = this
    this.lifeService.getPoint(this.$route.params.point).then((data) => {
      t.record = data
      t.keywords = t.record.keywords ? t.record.keywords.split(";") : ""
    })
    //if (!this.record.title) this.$router.push("/404")
    window.addEventListener("resize", () => {
      this.windowWidth = window.innerWidth
    })
  },
  methods: {
    tooltip(somehtml) {
      if (somehtml) {
        //<t text="some tooltiptext">some text</t>
        //<div class="tooltip">some text<span class="tooltiptext">some tooltiptext</span></div>
        var newstring =  somehtml.replace(/<t text="(.*?)">(.*?)<\/t>/g, function(match, p1, p2) {
          return "<div class='tooltip'>" + p2 + "<span class='tooltiptext'>" + p1 + "</span></div>"
          //console.log(match, p1, p2, offset)
        })
        return newstring
      }
    },
    appended() {
      console.log('appended')
    }
  },
}
</script>

<style>
.record {
  width: 100%;
  background-color: #201022;
  color: white;
}
.topimage {
  height: 590px;
}
.data {
  margin-left: 294px;
  margin-top: 35px;
  margin-right: 294px;
}
.data img {
  max-width: 100%;
}
.title {
  margin-top: 20px;
}
.keyword {
  background-color: #ef5e33;
  height: 50px;
  width: fit-content;
  padding: 11px;
  cursor: pointer;
  margin: 30px 20px 20px 0px;
}
.backlink {
  position: absolute;
  top: 104px;
  left: 69px;
  cursor: pointer;
}
.backlink img {
  margin-right: 10px;
}
.tooltip {
  text-decoration: underline;
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black; /* If you want dots under the hoverable text */
}
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  bottom: 100%;
  left: 50%;
  font-size: x-small;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
  position: absolute;
  z-index: 1;
  width: 300px;
}
.tooltip:hover .tooltiptext {
  visibility: visible;
}
</style>
